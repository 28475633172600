h1,
p {
    text-align : center;
  }

table {
  border-collapse: collapse;
/* position: relative; */
/* overflow-x: auto;
   display: block; */  
}

thead tr th {
  font-weight: 600;
  text-align: center;
 /*  background-color: #000000;
  color: #fff; */
}

img.coinlogo {
  width: 100%;
}

td.coinlogo-width {
  width: 75px;
}

.crytotable tbody tr:nth-child(odd) {
  background-color: rgba(0,0,0,.05);
}

th,
td {
  padding: 8px;
  overflow-wrap: break-word;
  text-align: center;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

table.crytotable {
  margin: 0 40px 40px;
  border: 1px solid #dee2e6;
}

table.crytotable td, table.crytotable th {
  border: 1px solid #dee2e6;
}

td.coinlogo-width {
  display: block;
}


@media only screen and (max-width: 1300px) {
  table.crytotable {
    overflow-x: auto;
    display: block;
  }
}