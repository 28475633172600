/* .page .navbar-b.navbar-trans .nav-link{
	color: #000;
}
.page .navbar-b h3{
	color: #000;
}
.page .navbar-b i{
	color: #000;
}
.page .nav-link{
	color: #000 !important;
} */
.contact-area .single-store-location p{
	/* color: #fff; */
}
.pages-banner{

	background-repeat: no-repeat;
	background-size: cover;
	color: #fff;
	padding: 100px 0 50px;
	height: 200px;
}
.pages-banner h1{
	color: #fff;
	position: absolute;
    z-index: 9;
}
.pages-banner:after{
	content: '';
    position: absolute;
    background: linear-gradient(90deg,#000000,#9c27b0 50%,#000000);
    width: 100%;
    height: 200px;
    top: 0;
    left: 0;
    z-index: 0;
	opacity: 0.9;
	border-bottom: 5px solid #4a4cb5;
}