/*******************************************************
    Template Name    : Techlabs - IT Solutions & Business Services Website Template
*******************************************************/

@import url("odometer.min.css");

/**************************************
 * Generale Style
 *************************************/

body {
  /* background: #fff;
  color: #333; */
  /* font-family: 'Mukta', sans-serif; */
  /* font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 26px;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 0;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; */
}
.navbar-b::after {
  position: absolute;
  left: 0;
  right: 0;
  top: -5px;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  height: 30%;
 
}

h1 {
  /* font-family: 'Righteous', cursive; */
  /* font-family: "Oswald", sans-serif; */
}

h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  /* font-family: 'Rajdhani', sans-serif; */
  font-family: "Oswald", sans-serif;
  font-weight: 100;
  color: #333;
  line-height: 1.5; 
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: inherit;
}

.mainmenu .nav, .fin_agr .nav{
  align-items: baseline;
}

/* .fin_agr .nav{
  margin-bottom: 20px;
} */

/* button for chat support */

.fa-commenting {
  margin-right: 10px;
  font-size: 40px;
}

.App{
  width: 350px;
  margin: auto;
  }
  .ToggleSwitch {
  background: #F1F1F1;
  border: 1px solid #F1F1F1;
  height: 1.7em;
  width: 4em;
  border-radius: 1em;
  }

.ToggleSwitch.activebg {
  background: #00B1BB;
  transition: all 0.5s ease;
}

button.ToggleSwitch {
  position: absolute;
  top: 50px;
  right: 150px;
  z-index: 999999;
}

.tablemargin{
  margin-top: 0px;
}

.menuitem {
  display: flex;
  padding-left: 110px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.mc_co{
  padding-left: 205px;
}

.comd{
  padding-left: 395px;
}

.form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
    color: #00b1bb;
}

.form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
  background-color: #00b1bb;
}

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
  border-color: #00b1bb;
}

.form-check {
  padding: 0 20px;
  width: 200px;
}

.form-check div.ddm{
  /* position: absolute; */
}

  .knob {
    background-color: #fff;
    position: relative;
    width: 1.7em;
    height: 1.7em;
    
    bottom: 1px;
    
    border: 1px solid #fff;
    border-radius: 50%;
    left: 0em;
    transition: left 0.3s ease-out 
  }
  .ToggleSwitch .active{
    background-color: #fff;
    border: 1px solid #fff;
      left: 44px;
    bottom: 1px;
  }



nav.navbar .btn-primary:hover {
  background-image: linear-gradient(-161deg, #f78d1e 0%, #fd1c1c 100%);
  box-shadow: 2.5px 1.99px 40px 0 rgb(250 29 29 / 99%);
  text-shadow: 0 -1px 0 rgb(216 110 0 / 90%);
  background-color: #ffe614;
  /* color: #fff; */
  text-decoration: none;
  border: none;
}
/* button for chat support */
a {
  /* color: #fff; */
  text-decoration: none !important;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
img {
  max-width: 100%;
}

a:hover {
  /* color: #000; */
  /* -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s; */
  color: inherit;
}

a:hover,
a:focus {
  /* color: #fff;
  text-decoration: none; */
}
p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #333;
  font-weight: 400;
  line-height: 26px;
  /* font-family: 'Mukta', sans-serif; */
  font-family: "Montserrat", sans-serif;
}
.bg-dark {
  background: #17171b !important;
}
.z-index {
  z-index: 9;
}
.position-relative {
  position: relative !important;
}
.form-control:focus {
  box-shadow: none;
  outline: 0 none;
  border-bottom: 1px solid #000;
}
.btn:focus {
  outline: none !important;
  box-shadow: none;
}
button:focus {
  outline: 0 none;
  box-shadow: none;
}
.button:focus {
  outline: none;
  box-shadow: none;
}
.top-btn {
  border: 1px solid #fff !important;
  color: #fff !important;
}
.button {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 15px 50px;
  text-align: center;
  font-size: 22px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  font-weight: 600;
  border: 1px solid #000;
  /* background: transparent; */
  background: linear-gradient(90deg, #000, #9c27b0 50%, #000);
  border-radius: 0px;
  cursor: pointer;
  /* color: #fff; */
  margin-top: 50px;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  will-change: transform;
  border-radius: 30px;
}
.button:hover {
  /* color: #fff; */
}

.button:before,
.button:after {
  content: "";
  width: 0;
  height: 100%;
  /* background: linear-gradient(90deg,#000,#9c27b0 50%,#000); */
  background: #14a8a6;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: width 0.4s;
  transition: width 0.4s;
  z-index: -1;
  opacity: 0.4;
}

.button:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  /* background: linear-gradient(90deg,#000,#9c27b0 50%,#000); */
  background: #14a8a6;
  opacity: 1;
}

.button:hover:before,
.button:hover:after {
  width: 100%;
}

.button:hover:after {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.bg-gray {
  background-color: #f8f8f9;
}

.bg-none {
  background: none !important;
}

.color-text {
  /* color: #000; */
}

dl,
ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.mr-15 {
  margin-right: 15px;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
}

.section-padding {
  padding: 100px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  margin-bottom: 65px;
}

/* .section-title h5 {
  background: linear-gradient(90deg, #000, #9c27b0 50%, #000);
  color: #fff !important;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 5px;
  display: inline-table;
  padding: 2px 23px;
  border-radius: 30px;
} */

.section-title h2 {
  /* color: #000 */;
  font-size: 40px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1.3;
  /* font-family: 'Rajdhani', sans-serif; */
  letter-spacing: 2px;
}

/* .section-title h2.text-clr-white {
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-size: 50px;
  font-weight: 300;
  letter-spacing: 10px;
} */

/* .sec-services h3 {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  padding-bottom: 20px;
  font-size: 18px;
  letter-spacing: 3px;
} */

/* .sec-services p {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  line-height: 22px;
} */

.style2 {
  font-size: 40px;
  letter-spacing: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  line-height: 65px;
}

.display-table {
  width: 100%;
  height: 100%;
  display: table;
}
.table-cell {
  display: table-cell;
  vertical-align: middle;
}
.mr-0 {
  margin: 0;
}
.mr-top-50 {
  margin-top: 50px;
}
/* .back-to-top {
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 15px;
  color: #ffffff;
  background: linear-gradient(90deg, #000, #9c27b0 50%, #000);
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 42px;
  line-height: 42px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}
.back-to-top:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #ffc518;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.back-to-top:hover:before,
.back-to-top:focus:before {
  opacity: 1;
  visibility: visible;
}
.back-to-top:hover,
.back-to-top:focus {
  color: #ffffff;
}
.back-to-top.active {
  top: 90%;
  -webkit-transform: translateY(-98%);
  transform: translateY(-98%);
  opacity: 1;
  visibility: visible;
}
.back-to-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}
.back-to-top:hover i:first-child,
.back-to-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.back-to-top:hover i:last-child,
.back-to-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}
.back-to-top i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 20px;
} */

/* Preloader Css */
img.white-logo,
img.black-logo {
  height: auto;
  width: 125px;
}

#preloader .black-logo {
  height: 30vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* background: url("../assets/img/White-Background.gif") no-repeat center center; */
  background-size: 200px;
  width: auto;
}

/* Preloader Css */

.v-middle {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}
.valign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/* [ Start Overlay ] */

[data-overlay-dark],
[data-overlay-light] {
  position: relative;
}
[data-overlay-dark] .container,
[data-overlay-light] .container {
  position: relative;
  z-index: 2;
}
[data-overlay-dark]:before,
[data-overlay-light]:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  z-index: 1;
  /* transform: rotate(30deg); */
}
[data-overlay-dark]:before {
  /* background: linear-gradient(90deg,#000,#9c27b0 50%,#000);  */
  background: #1a0013;
  /* linear-gradient(90deg,#9c27b0,#000 50%,#9c27b0) */
}
[data-overlay-light]:before {
  background: #fff;
}
[data-overlay-dark]:after,
[data-overlay-light]:after {
  content: "";
  position: absolute;
  /* background: url(../img//home-bottom-shape.png); */
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 30%;
  z-index: 1;
}
[data-overlay-dark] h1,
[data-overlay-dark] h2,
[data-overlay-dark] h3,
[data-overlay-dark] h4,
[data-overlay-dark] h5,
[data-overlay-dark] h6,
[data-overlay-dark] span,
.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark span,
.bg-color h1,
.bg-color h2,
.bg-color h3,
.bg-color h4,
.bg-color h5,
.bg-color h6,
.bg-color span {
  /* color: #fff; */
}
.bg-color h2 {
  /* color: #000 */;
}
.bg-color {
  background: #f8f8f9;
}
[data-overlay-dark] p,
.bg-dark p,
.bg-color p {
  /* color: #000 */;
}
[data-overlay-dark="0"]:before,
[data-overlay-light="0"]:before {
  opacity: 0;
}
[data-overlay-dark="1"]:before,
[data-overlay-light="1"]:before {
  opacity: 0.1;
}
[data-overlay-dark="2"]:before,
[data-overlay-light="2"]:before {
  opacity: 0.2;
}
[data-overlay-dark="3"]:before,
[data-overlay-light="3"]:before {
  opacity: 0.3;
}
[data-overlay-dark="4"]:before,
[data-overlay-light="4"]:before {
  opacity: 0.4;
}
[data-overlay-dark="5"]:before,
[data-overlay-light="5"]:before {
  opacity: 0.8;
}
[data-overlay-dark="5"]:after,
[data-overlay-light="5"]:after {
  opacity: 1;
}
[data-overlay-dark="6"]:before,
[data-overlay-light="6"]:before {
  opacity: 0.6;
}
[data-overlay-dark="7"]:before,
[data-overlay-light="7"]:before {
  opacity: 0.7;
}
[data-overlay-dark="8"]:before,
[data-overlay-light="8"]:before {
  opacity: 0.8;
}
[data-overlay-dark="9"]:before,
[data-overlay-light="9"]:before,
[data-overlay-color="9"]:before {
  opacity: 0.9;
}
[data-overlay-dark="10"]:before,
[data-overlay-light="10"]:before {
  opacity: 1;
}
/* [ End Overlay ] */

/* owl theme */

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 20px;
}
.owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 7px;
  margin: 5px 5px;
  background-color: #777;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 5px;
  transition: 0.3s;
  border: 2px solid transparent;
}
.owl-theme .owl-dots .owl-dot.active span {
  background-color: #000 !important;
  height: 7px;
  transition: 0.3s;
}
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: transparent;
  border: 2px solid #000;
  transition: 0.3s;
}

/**************************************
 * Preloader Area CSS
 **************************************/

#preloader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999999;
}

#preloader .line {
  position: absolute;
  border-radius: 50%;
  border: 2px solid #fff;
  border-bottom: 2px solid #000;
}

#preloader .line:nth-child(1) {
  width: 25px;
  height: 25px;
  animation: circle-anim 600ms linear infinite;
}

#preloader .line:nth-child(2) {
  width: 50px;
  height: 50px;
  animation: circle-anim 800ms linear infinite;
}

#preloader .line:nth-child(3) {
  width: 75px;
  height: 75px;
  animation: circle-anim 1000ms linear infinite;
}

#preloader .line:nth-child(4) {
  width: 100px;
  height: 100px;
  animation: circle-anim 1200ms linear infinite;
}

@keyframes circle-anim {
  to {
    transform: rotate(360deg);
  }
}

/**************************************
 * Navber Area Style
 *************************************/
.header-navber-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 999;
}
.login-register a {
  /* color: #fff; */
  font-size: 18px;
  font-family: "Oswald", sans-serif;
}

.header-navber-area .nav-top-bar {
  background: #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 15px;
  padding-bottom: 15px;
}

.header-navber-area .nav-top-bar .call-to-action p {
  margin: 0 13px 0 0;
  display: inline-block;
  color: #ffffff;
  font-size: 15px;
}

.header-navber-area .nav-top-bar .call-to-action p i {
  margin-right: 3px;
  font-size: 16px;
}

.header-navber-area .nav-top-bar .call-to-action p a {
  color: #ffffff;
}

.header-navber-area .nav-top-bar .call-to-action p a:hover,
.header-navber-area .nav-top-bar .call-to-action p a:focus {
  /* color: #000; */
}

.header-navber-area .nav-top-bar .top-social {
  float: right;
  padding: 0;
  margin: 0 0 0 10px;
  list-style-type: none;
}

.header-navber-area .top-menu .navbar-reduce {
  background: transparent;
}

.header-navber-area .nav-top-bar .top-social li {
  display: inline-block;
}

.header-navber-area .nav-top-bar .top-social li a {
  color: #ffffff;
  font-size: 15px;
  margin-left: 5px;
}

.header-navber-area .nav-top-bar .top-social li a:hover,
.header-navber-area .nav-top-bar .top-social li a:focus {
  /* color: #000; */
}

.header-navber-area .nav-top-bar .opening-hours {
  float: right;
}

.header-navber-area .nav-top-bar .opening-hours p {
  font-size: 15px;
  color: #ffffff;
  margin-bottom: 0;
}

.header-navber-area .nav-top-bar .opening-hours p i {
  margin-right: 3px;
  font-size: 16px;
}

.header-navber-area .nav-top-bar.template-color-bg {
  background: linear-gradient(90deg, #000, #9c27b0 50%, #000);
  border-bottom: none;
}

.header-navber-area .nav-top-bar.template-color-bg .call-to-action p a {
  color: #ffffff;
}

.header-navber-area .nav-top-bar.template-color-bg .top-social li a {
  color: #ffffff;
}
.navbar-b.navbar-reduce .login-register a {
  /* color: #000; */
}

.navbar-b {
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  padding-top: 15px;
  padding-bottom: 15px;
}
.navbar-b h3 {
  /* color: #fff; */
  align-items: center;
  display: flex;
}
.navbar-b.navbar-reduce h3 {
  /* color: #000; */
}
.navbar-b.navbar-reduce i {
  /* color: #000; */
}
.navbar-b.navbar-reduce {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
}
.navbar-b.navbar-trans .nav-item,
.navbar-b.navbar-reduce .nav-item {
  position: relative;
}

.navbar-b.navbar-trans .nav-link {
  color: /* rgb(216, 2, 2) */ #0d6efd;
  /* text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.7px;
  padding-right: 15px;
  padding-left: 15px; */
  /* font-family: 'Mukta', sans-serif; */
  /* font-family: "Oswald", sans-serif; */
}

.navbar-b.navbar-reduce .nav-link {
  /* color: #000; */
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.7px;
  padding-right: 15px;
  padding-left: 15px;
  /* font-family: 'Mukta', sans-serif; */
  font-family: "Oswald", sans-serif;
}
.navbar-b.navbar-trans .nav-link:hover,
.navbar-b.navbar-reduce .nav-link:hover {
  color: rgb(255, 255, 255);
}
.navbar-b.navbar-trans .nav-link:hover {
  color: #0a58ca;
}
.navbar-b.navbar-trans .show > .nav-link,
.navbar-b.navbar-trans .active > .nav-link,
.navbar-b.navbar-trans .nav-link.show,
.navbar-b.navbar-trans .nav-link.active {
  /* color: rgb(253, 253, 253); */
  color: #0d6efd;
}

.navbar-b.navbar-reduce {
  transition: all 0.5s ease-in-out;
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-animation: 500ms ease-in-out 0s normal fadeInDown;
  animation: 500ms ease-in-out 0s normal fadeInDown;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}
.navbar-b.navbar-reduce .nav-link {
  /* color: #333; */
}
.navbar-b.navbar-reduce .nav-link:hover {
  color: #7e7e7e;
}
.navbar-b.navbar-reduce .show > .nav-link,
.navbar-b.navbar-reduce .active > .nav-link,
.navbar-b.navbar-reduce .nav-link.show,
.navbar-b.navbar-reduce .nav-link.active {
  /* color: #000; */
}
.navbar-b.navbar-reduce .navbar-brand {
 /*  color: #fff; */
}
.navbar-b.navbar-reduce .navbar-toggler span {
  background-color: #000;
}
.navbar-b .navbar-brand {
  /* color: #fff; */
  font-size: 1.6rem;
  font-weight: 700;
  max-width: 450px;
}
.navbar-nav {
  /* background-image: linear-gradient(180deg,#000 16%,#e62d20 52%); */
  /* position: absolute; */
  /* top: 100%; */
  z-index: 90;
  line-height: 22px;
  margin-right: 10px;
}

.navbar-b .navbar-nav .dropdown-item.show .dropdown-menu,
.navbar-b .dropdown.show .dropdown-menu,
.navbar-b .dropdown-btn.show .dropdown-menu {
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  visibility: visible !important;
}
.navbar-b .dropdown-menu {
  margin: 1.12rem 0 0;
  border-radius: 0;
}
.navbar-b .dropdown-menu .dropdown-item {
  padding: 0.7rem 1.7rem;
  transition: all 500ms ease;
}
.navbar-b .dropdown-menu .dropdown-item:hover {
  /* background-color: #000;
  color: #fff;
  transition: all 500ms ease; */
}

.navbar-b .dropdown-menu .dropdown-item.active {
  background-color: #000;
}

.navbar-toggler {
  position: relative;
  padding: .25rem .75rem !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}

.navbar-toggler span {
  /* display: block;
  background-color: #fff;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  left: 0;
  opacity: 1; */
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: -webkit-transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  /* position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9; */
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9;
}

.navbar-b .black-logo {
  display: none;
}

.navbar-b.navbar-reduce .navbar-brand .white-logo {
  display: none;
}

.navbar-b.navbar-reduce .navbar-brand .black-logo {
  display: block;
}

.top-icon {
  /* color: #fff; */
  width: 34px;
  height: 34px;
  line-height: 25px;
}

.shapeclr {
  height: 70px;
  width: 70px;
  fill: white;
}

.svg-shape-top .bottom-shape {
  position: absolute;
  left: 0;
  /* right: 0; */
  top: -12px;
  z-index: 1;
  width: 600px;
  margin: 0 auto;
}

/**************************************
 * Home Slider Style
 *************************************/

.slider {
  position: relative;
}
.svg-shape-bottom .bottom-shape {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  height: 25%;
}
/* .swiper-wrapper::after{
	position: absolute;
    top: 0;
    content: "";
    left: 0px;
    padding: 0;
    height: 100%;
    width: 100%;
    opacity: .8;
    z-index: 1;
    background: linear-gradient(90deg,#000,#9c27b0 50%,#000);
    background: -webkit-linear-gradient(to right, #000, #000);
    background: linear-gradient(to right, #000, #000);
} */
.slider.fixed-slider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.slider .swiper-slide-active {
  z-index: 3;
}
.slider .parallax-slider {
  position: relative;
}
.slider .parallax-slider .swiper-slide {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
}
.slider .parallax-slider .swiper-slide .bg-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: top;
}
.slider .parallax-slider .swiper-slide-active .caption h1 {
  opacity: 1;
  animation: fadeInLeft 0.8s;
  -webkit-animation-delay: 1s;
  animation-delay: 0.3s;
}
.slider .parallax-slider .caption {
  margin-top: 80px;
}
.slider .parallax-slider .caption h3 {
  margin-bottom: 20px;
}
.slider .parallax-slider .caption h1 {
  font-size: /* 110px */ 80px;
  line-height: 65px;
  /* font-weight: 700; */
  /* color: #fff; */
  opacity: 1;
  visibility: visible;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 20px;

  /* background: linear-gradient(to left, #000 25%, #9c27b0 50%, #000 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.net-sol {
  width: 90px;
  height: 90px;
  fill: #a80382;
}

.services-area .services-single-item:hover .net-sol {
  fill: white;
}

.slider .parallax-slider .caption h2 {
  color: #fdfdff;
  letter-spacing: 1px;
  line-height: 50px;
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
}
.slider .parallax-slider .caption p {
  /* color: #fff; */
  font-weight: 400;
  margin-top: 10px;
  opacity: 1;
}
.slider .parallax-slider .swiper-slide-active .caption p {
 /* color: #fff; */
  font-weight: 400;
  margin-top: 10px;
  opacity: 1;
  animation: fadeInRight 0.8s;
  margin-bottom: 30px;
}
.slider .parallax-slider .swiper-slide-active .caption .home-slider-btn {
  font-size: 16px;
  font-weight: 300;
  text-transform: capitalize;
  letter-spacing: 1px;
  position: relative;
  opacity: 1;
  animation: fadeInUp 1s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.slider .control-text {
  position: absolute;
  top: 60%;
  z-index: 8;
  width: 100%;
  height: auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.slider .control-text .swiper-nav-ctrl {
  color: #eee;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-left: 0px;
  margin-right: 0px;
  position: static;
  width: auto;
  height: auto;
  display: inline-block;
  background: transparent;
  /* border-radius: 3px; */
}

.slider .control-text .swiper-nav-ctrl:after {
  font-size: 25px;
  width: 30px;
  height: 50px;
  line-height: 50px;
  display: block;
  text-align: center;
  background: rgb(100 98 95 / 40%);
}
.slider .swiper-pagination-bullets {
  bottom: 20px;
}
.slider .swiper-pagination-bullet {
  background: #fff;
  opacity: 0.4;
}
.slider .swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}

/**************************************
 * Services Section Style
 *************************************/
.services-single-item .number {
  color: #ececec;
  font-size: 60px;
  font-weight: 900;
  position: absolute;
  bottom: 0px;
  padding: 0px 10px;
  z-index: 90;
  background: linear-gradient(90deg, #000, #9c27b0 50%, #000);
  line-height: 56px;
}
.services-area .services-single-item {
  padding: 0px;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
  /* background-position: 50% 0% !important; */
  /* border: 4px solid #e6e6e6; */
  margin-bottom: 15px;
  margin-top: 15px;
}
.services-area .services-single-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
  background: #fff;
}
.services-area .services-single-item:hover:after {
  opacity: 0.8;
  background: linear-gradient(90deg, #000, #9c27b0 50%, #000);
}
.services-area .services-single-item:hover h5,
.services-area .services-single-item:hover p,
.services-area .services-single-item:hover h5,
.services-area .services-single-item:hover i,
.services-area .services-single-item:hover span {
 /* color: #fff; */
}
.services-area .services-single-item:hover .count-number {
  background-color: #e1e1e1;
}
.services-area .services-single-item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.services-area .services-single-item .services-content {
  position: relative;
  z-index: 7;
  /* background: rgb(255 0 0 / 66%); */
  padding: 50px 15px;
  text-align: center;
}
.services-area .services-single-item h5 {
  /* color: #000; */
  font-size: 22px;
  font-weight: 700;
  /* margin-bottom: 15px; */
  letter-spacing: 2px;
}
.services-area .services-single-item i {
  color: #4caf50;
  font-size: 60px;
  font-weight: 100;
}
.services-area .services-single-item p {
  /* color: #000; */
  padding: 0 30px;
  height: 104px;
}
.services-area .services-single-item .count-number {
  font-size: 65px;
  line-height: 75px;
  font-weight: 400;
  background-color: #e1e1e1;
  display: inline-block;
  width: 80px;
}
.owl-carousel .owl-stage-outer {
  overflow: hidden;
}

.text-clr-white {
 /* color: #fff; */
}

/**************************************
 * Timeline Section Style
 *************************************/

.timelines h2 {
  text-align: center;
 /* color: #fff; */
  font-weight: 600;
  margin-bottom: 40px;
  font-size: 32px;
}
.d-flex-2 {
  display: flex;
  align-items: center;
}
.timeline-area {
  padding: 80px 0;
}
.all-timelines {
  position: relative;
}
.timelines h2 {
  text-align: center;
 /* color: #fff; */
  font-weight: 600;
  margin-bottom: 40px;
}
.all-timelines::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 2px;
  background: #d5d5d5;
  top: 20px;
}
.single-timeline {
  margin-bottom: 22px;
}
.timeline-blank {
  width: 50%;
}
.timeline-text {
  width: 50%;
  padding-left: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
.timeline-text h6 {
  /* color: #000; */
  font-weight: 900;
  display: inline-block;
  font-size: 1rem;
}
.timeline-text span {
  display: block;
  width: 100%;
}
.single-timeline:nth-child(even) .timeline-text .span {
  text-align: right;
  width: 100%;
}
.t-square {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: -6px;
  background: linear-gradient(90deg, #000, #9c27b0 50%, #000);
}
.single-timeline:nth-child(even) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.single-timeline:nth-child(even) .t-square {
  right: -6px;
  left: unset;
}
.single-timeline:nth-child(even) .timeline-text {
  padding-left: 0;
  padding-right: 30px;
  text-align: right;
}

.modal-content iframe .shadow-4{
  background: pink;
}

.imgframe .shadow-4{
  background: pink;
}

iframe body picture.shadow-4 {
  background: pink;
}

@media all and (max-width: 991px) {
}
@media all and (max-width: 768px) {
  .all-timelines::before {
    right: unset;
    top: 0;
  }
  .single-timeline:nth-child(2n) .timeline-text {
    padding-left: 30px;
    padding-right: 0;
    text-align: left;
  }
  .single-timeline:nth-child(2n) .t-square {
    left: -6px;
    right: unset;
  }
  .timeline-blank {
    display: none;
  }
  .timeline-text {
    width: 100%;
  }
  .single-timeline:nth-child(even) .timeline-text span {
    text-align: left !important;
  }
}
@media all and (max-width: 991px) {
  .single-timeline:nth-child(even) .timeline-text .span {
    text-align: left;
    width: 100%;
  }
}
@media all and (max-width: 360px) {
  .all-timelines::before {
    top: 32px;
  }
}

/**************************************
 * About Section Style
 *************************************/

.about-area {
}
.project-completed .single-project {
  background: #f8f8f9;
  padding: 38px;
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.project-completed .single-project span {
  font-size: 50px;
  /* color: #000; */
  float: right;
}
.banner-project-completed {
  margin-top: 30px;
}
.banner-project-completed .single-project {
  background: rgb(0 0 0 / 51%);
  padding: 20px;
  font-size: 14px;
  display: grid;
 /* color: #fff; */
  margin-bottom: 20px;
  opacity: 0.9;
}
.banner-project-completed .single-project span {
  padding: 20px;
  font-size: 40px;
 /* color: #fff; */
}

.homepage .about-content-text:before {
  content: "";
  background: #a80382;
  width: 103%;
  height: 15px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  z-index: -2;
  top: -8px;
}

.homepage .about-content-text:after {
  content: "";
  background: #a80382;
  /* color: white; */
  width: 103%;
  height: 16px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  z-index: -2;
  bottom: -8px;
}

.about-content-text {
  position: relative;
}

.about-content h2 {
  /* font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: normal; */
  font-size: 70px;
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 80px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 0px;
  /* background: white; */
  font-family: "Oswald", sans-serif;
  margin-top: 8px;
}

._ss_sign_up {
  padding: 11px 40px;
  font-family: Montserrat;
  letter-spacing: 1px;
 /* color: #fff; */
  background-color: #000;
  border-color: #000 !important;
  width: 100%;
  max-width: 570px;
  margin: 0 auto;
  border: 0;
  font-size: 12px;
}

.homepage .about-content-text p {
  text-align: center;
}

.about-content-text p {
  margin-bottom: 20px;
  text-align: left;
  background: #fff;
  padding: 0 30px 10px 30px;
  font-family: "Montserrat", sans-serif;
}

.pt0 {
  padding-top: 0;
}

.about-content-text h3 {
  /* color: #000; */
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 5px;
  margin-bottom: 10px;
}

.about-content-text img {
  width: 42%;
  margin: 0 auto;
  display: block;
}

.owl-carousel .hes-gallery img {
  display: none;
}

.owl-carousel .hes-gallery img.galfrimg {
  display: block;
  height: 53px;
  width: 175px;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  opacity: 0;
}

.owl-carousel .owl-item img.gallery_btn {
  height: auto;
  width: auto;
  border-radius: 0;
}

/* .vdobtn{
	position: absolute;
    top: 0px;
    background: transparent;
    border: none;
    width: 200px;
    height: 200px;
    left: 85px;
	text-indent: -999999px;
}
button:hover.vdobtn, button:focus.vdobtn, button:active.vdobtn{
    background: transparent !important;
	text-indent: -9999px !important;
	border: none !important;
	box-shadow: none !important;
} */

.player {
  max-width: 100% !important;
}

#player {
  max-width: 100% !important;
}

.btn.upcoming,
.btn.past {
  padding: 0.375rem 2.75rem;
  font-size: 1.5rem;
  line-height: 2;
  background: linear-gradient(90deg, #000, #9c27b0 50%, #000);
  border: none;
  margin: 10px 0;
}

.ft-thirty {
  text-align: left;
  margin-bottom: 20px;
}

.ft-thirty p {
  line-height: 2;
}
h2.ft-thirty {
  font-size: 26px;
}

.btn.upcoming:hover,
.btn.past:hover {
  background: #14a8a6;
}

.modal-content {
  background-color: transparent;
  border: none;
  width: 800px;
}
.product.grid.px-5 {
  width: 880px;
}
p.abtpra {
  text-align: justify;
  margin-top: 38px;
}

.paratxt {
  line-height: 22px;
}
.paratxt strong {
 /* color: #fff; */
}
.parafont {
  font-size: 14px;
}
.para-black strong {
  /* color: #000; */
}
.modal-dialog {
  max-width: 750px;
  margin: 1.75rem auto;
}

.modalwd {
  max-width: 450px;
}

button.close {
  /* position: absolute;
    right: -28px;
    color: black;
    cursor: pointer;
    background: #fff;
    border-radius: 50px;
    width: 40px;
    top: 35%;
    height: 40px; */
}

.modal-backdrop.show {
  z-index: 999;
}

.close {
  /* color: #fff; */
}

.modal-backdrop.show {
  opacity: 0.9;
}

.modal-body {
  display: flex;
  justify-content: space-between;
  background: #fff;
}

.mainsec {
  background: #000bd;
  flex-direction: column;
  border-radius: 8px;
  border: solid 1px #2d282e;
}

.about-image {
  text-align: center;
  position: relative;
}

.testimonial-content-inner .about-image {
  text-align: center;
  position: relative;
}

.about-image p {
  max-width: 200px;
  margin: 0 auto;
}

.about-image p.city {
  max-width: 100%;
  margin: 20px auto;
  font-size: 22px;
  border-bottom: solid 1px #dbdbdb;
  padding-bottom: 20px;
}

.about-image p.city i {
  color: #9c27b0;
}

.owl-carousel .owl-item img {
  width: 200px;
  height: 200px;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 50%;
  margin: 0 auto;
}

.owl-carousel .owl-item img.circle {
  width: auto;
  height: auto;
  background-size: auto !important;
  background-position: auto !important;
  border-radius: 0%;
  margin: 0;
}

.waves-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

._ss_md_review_start_div {
  padding: 20px 0;
}

.testimonial-content-inner .waves-box {
  top: 38%;
}

.about-image .iq-video {
  background: transparent;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 25px;
  /* color: #000; */
  float: left;
  border-radius: 100%;
  line-height: 62px;
  z-index: 9;
  position: relative;
}
.about-image .iq-video i {
  margin-left: 5px;
}
.about-image .iq-waves {
  position: absolute;
  left: -30px;
  top: -30px;
  z-index: 2;
}
.about-image .iq-waves .waves {
  position: absolute;
  width: 120px;
  height: 120px;
  background: rgb(255 255 255);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 50%;
  background-clip: padding-box;
  -webkit-animation: waves 1s ease-in-out infinite;
  animation: waves 1s ease-in-out infinite;
}
.about-image .iq-waves .wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.about-image .iq-waves .wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.about-image .iq-waves .wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
@-webkit-keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

/**************************************
 * counter style
 *************************************/
.counter-area {
  background: linear-gradient(90deg, #000, #9c27b0 50%, #000);
}
.counter-inner-box {
  margin-top: 0px;
}
.single-counter-box {
  text-align: center;
}

.single-counter {
  padding: 15px 0px;
  position: relative;
  z-index: 1;
  display: -ms-inline-grid;
  display: inline-grid;
}

.single-counter i {
  color: #ffffff;
  font-size: 70px;
}

.single-counter h3 {
  margin-bottom: 0;
  /* color: #fff; */
  font-size: 45px;
  font-weight: 800;
}

.single-counter p {
  line-height: 1.5;
  color: #ffffff;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
}

/**************************************
 * 11.Pricing Section Style
 *************************************/

.price-area {
  background: #f4f7fa;
}

.price-area .section-title {
  text-align: center;
  margin-bottom: 35px;
}

.activeplan .plan-img {
  margin-top: -49px;
}
.activeplan img {
  width: 350px;
}

.price-area .container {
  max-width: 1300px;
}

.activeplan .single-price-item .pricing-header.bs p.pricing-description {
  top: 25%;
}

.activeplan .bs_txt {
  font-size: 38px;
  line-height: 60px;
}

.bs_txt {
  font-size: 27px;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  /* color: #fff; */
  text-shadow: 0 0 10px #b5780c;
}

.activeplan .single-price-item .pricing-header .pricing-description {
  font-weight: 600;
  font-size: 16px;
  /* color: #fff; */
  position: absolute;
  width: 100%;
  top: 38%;
  transform: perspective(1px) translateY(0%);
  padding: 0 42px;
}
.price-area .col-lg-3 {
  flex: 0 0 23%;
  max-width: 23%;
}

.col-lg-3.col-md-6.col-sm-12.price-item.activeplan {
  flex: 0 0 31%;
  max-width: 31%;
}

.col-lg-3.col-md-6.col-sm-12.price-item.activeplan .single-price-item {
  box-shadow: 0 10px 20px rgb(161 54 171);
  border: solid 3px #5d245d;
}

.single-price-item {
  background: #ffffff;
  box-shadow: 0 3px 30px -3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  overflow: inherit;
  margin-top: 30px;
  border-bottom: 3px solid rgba(145, 138, 255, 0);
  transition: 0.3s;
}

.single-price-item:hover {
  transform: translate3d(0, -5px, 0);
  -webkit-transform: translate3d(0, -5px, 0);
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #000;
  transition: 0.3s;
}

.single-price-item.active .pricing-header {
  position: relative;
  overflow: inherit;
}

.single-price-item.active .pricing-header:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  right: 0px;
  margin: auto;
}

.single-price-item.active .pricing-header .pricing-title {
  /* color: #fff; */
}

.single-price-item .pricing-header {
  /* border-bottom: 1px solid #f9fafa; */
  text-align: center;
  height: 190px;
  display: block;
  position: relative;
  background: #f9fafa;
}

.single-price-item .pricing-header .pricing-title {
  font-weight: 800;
  font-size: 25px;
  /* color: #fff; */
  position: absolute;
  width: 100%;
  top: 0%;
  transform: perspective(1px) translateY(0%);
  line-height: 1.25;
  padding: 0 25px;
}
.single-price-item .pricing-header .pricing-description {
  font-weight: 400;
  font-size: 14px;
  /* color: #000; */
  position: absolute;
  width: 100%;
  top: 65%;
  transform: perspective(1px) translateY(0%);
  padding: 0 10px;
}
.single-price-item.active .pricing-header .pricing-description {
  /* font-weight: 400;
	font-size: 14px;

	position: absolute;
	width: 100%;
	top: 40%;
	transform: perspective(1px) translateY(0%); */
}
.single-price-item .pricing-body {
  margin-bottom: 30px;
}

.single-price-item .pricing-body .price-icon {
  width: 100px;
  height: 90px;
  /* position: relative; */
  margin: auto;
  /* margin-top: -45px; */
  text-align: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
}
.single-price-item:hover .pricing-body .price-icon {
  animation: bounceIn 1s;
}

.single-price-item .pricing-body .price-icon i {
  font-size: 30px;
  /* color: #fff; */
  line-height: 90px;
}

.single-price-item .pricing-body .price-wrapper {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.single-price-item .pricing-body .price-wrapper .currency {
  height: 47px;
  font-weight: 600;
  font-size: 18px;
  /* color: #000; */
  position: relative;
  top: -15px;
}

.single-price-item .pricing-body .price-wrapper .price {
  font-weight: 700;
  font-size: 34px;
  /* color: #000; */
  letter-spacing: 1px;
}

.single-price-item .pricing-body .price-wrapper .period {
  font-weight: 700;
  font-size: 14px;
  color: #9e9e9e;
  letter-spacing: 0.88px;
}

.single-price-item .pricing-body .list li {
  text-align: center;
  margin-bottom: 5px;
  font-size: 14px;
  color: #877f89;
  letter-spacing: 0.5px;
  text-decoration: line-through;
}

.single-price-item:hover .pricing-header .pricing-title {
  /* color: white; */
}

.single-price-item .pricing-body .list li:nth-child(2n + 1) {
  background: #f5f5f5;
  padding-left: 10px;
  padding-right: 10px;
}

.single-price-item .pricing-body .list li.active {
  color: #9926ac;
  text-decoration: none;
}

.plan-img {
  margin-top: -33px;
  display: inline-block;
  margin-bottom: 30px;
}

.single-price-item .pricing-footer {
  text-align: center;
  background: #f9fafa;
  padding-top: 40px;
}

.single-price-item .pricing-footer a {
  margin-bottom: 40px;
  margin-top: 0;
}

/**************************************
 * Projects Section Style
 *************************************/

.projects-area .project-single-item {
  position: relative;
  overflow: hidden;
}

.projects-area .project-single-item img {
  -webkit-filter: none;
  filter: none;
  border-radius: 0px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.projects-area .project-single-item:hover img {
  -webkit-filter: none;
  filter: none;
  -webkit-transform: scale(1.09, 1.09);
  transform: scale(1.09, 1.09);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
/* .projects-area .project-single-item:hover .project-overlay-info {
    bottom: 0;
} */

.projects-area .project-single-item .project-overlay-info {
  padding: 10px;
  bottom: 75px;
  left: 0;
  width: 100%;
  display: block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: left;
  /* transform: rotate(-90deg); */

  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  /* -webkit-transform: rotate(-90deg); */

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.projects-area .project-single-item .project-overlay-info .category {
  position: relative;
  /* color: #000; */
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;
}
.projects-area .project-single-item .project-img {
  overflow: hidden;
}
.projects-area .project-single-item .project-overlay-info h5 {
  font-size: 24px;
  /* color: #000; */
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1.2;
}
.projects-area .project-single-item .project-overlay-info p {
  /* color: #fff; */
  font-size: 15px;
}
.projects-area .project-single-item .project-overlay-info i {
  /* color: #fff; */
  font-size: 50px;
  background: #000;
}

/**************************************
 * 08.Testimonial Section Style
 *************************************/

.testimonial-area {
  position: relative;
  z-index: 1;
}

.testimonial-area .owl-stage-outer {
  margin: 0 -8px;
  padding-left: 8px;
  padding-top: 0.35rem;
}
.testimonial-content-inner .testimonial-text {
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  padding: 30px 25px;
  text-align: center;
  border: 2px dashed #e1e1e1;
}

.testimonial-content-inner .testimonial-text p {
}

.testimonial-text .rating-box {
  margin-top: 10px;
}

.testimonial-text .rating-box h6 {
  font-size: 15px;
  font-weight: 700;
  color: #777;
}

.testimonial-text .rating-box ul li {
  display: inline-block;
}

.testimonial-text .rating-box ul li i {
  /* color: #000; */
  font-size: 15px;
}
.testimonial-content-inner .author-info-box {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: row;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  padding: 30px;
}
.testimonial-content-inner .author-info-box .author-img img {
  border-radius: 50%;
  border: 5px solid #fff;
  width: 55px;
  margin: 0 auto;
  -webkit-box-shadow: 0 3px 15px rgba(218, 225, 232, 0.85);
  box-shadow: 0 3px 15px rgba(218, 225, 232, 0.85);
}
.testimonial-content-inner .author-info-box .author-img {
  margin-right: 18px;
}
.testimonial-content-inner .author-info-box h3 {
  font-size: 20px;
  font-weight: 700;
}

.testimonial-content-inner .author-info-box span {
  font-size: 16px;
  font-weight: 500;
}

.testimonial-area .owl-theme .owl-nav.disabled + .owl-dots {
  position: unset;
  right: 0;
  top: 50%;
  transform: none;
  margin-top: 20px;
}
.testimonial-area .owl-theme .owl-dots .owl-dot span {
  background-color: #ddd;
}

/**************************************
 * 09. Team Section Style
 *************************************/

.team-area {
}

.team-area .section-title {
  margin-bottom: 35px;
}

.single-team-box {
  margin-top: 30px;
  overflow: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #e7e7e7;
}
.team-image {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.team-image::before {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.single-team-box:hover .team-image::before {
  opacity: 0.8;
  visibility: visible;
}

.single-team-box .team-image img {
  width: 100%;
  height: auto;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-team-box:hover .team-image img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.team-social-icon {
  position: absolute;
  width: 77%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-51%);
  margin-top: 30px;
}

.team-social-icon a {
  border: 1px solid #cbcbcb;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding: 5px 5px;
  color: #919191;
  font-size: 14px;
  margin: 0 5px;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  will-change: transform;
}

.team-info {
  padding: 20px 15px 0px;
  text-align: center;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.team-info h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}
.team-info span {
  /* color: #000; */
  font-size: 16px;
  font-weight: 400;
  display: block;
}

/**************************************
 * 10.Blog Section Style
 *************************************/

.blog-area {
  background: #fbfbfb;
}
.blog-area .section-title {
  margin-bottom: 35px;
}

.blog-slingle-item {
  margin-top: 30px;
  padding: 20px;
  background: #f6f6f6;
}

.blog-area .blog-slingle-item .post-img {
  overflow: hidden;
  position: relative;
}

.blog-slingle-item .post-img img {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.blog-slingle-item .post-img:hover img {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.blog-slingle-item .blog-content-info {
  padding: 20px 20px 0 0;
}
.blog-area .blog-slingle-item .blog-content-info h6 a {
  color: #333;
  font-size: 22px;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
}
.blog-area .blog-slingle-item .blog-content-info h6 a:hover {
  /* color: #000; */
}
.blog-area .blog-slingle-item .blog-content-info h5 {
  font-size: 40px;
  margin-bottom: 5px;
}
.blog-area .author-date {
  position: relative;
  line-height: 38px;
  padding-left: 10px;
}
.blog-area .author-date:after {
  content: "";
  position: absolute;
  border: 0 solid transparent;
  width: 0;
  right: 0;
  height: 0;
  border-top: 100px solid #fbfbfb;
  border-left: 100px solid transparent;
}
.blog-area .author-date a {
  font-size: 15px;
  color: #ccc;
  letter-spacing: 2px;
}
.blog-area .author-date a {
  margin-right: 0px;
}
.blog-area .author-date a:last-child {
  margin-right: 0;
}
.blog-area .author-date b {
  /* color: #fff; */
  padding-right: 5px;
  letter-spacing: 4px;
}

/**************************************
 * 11. Contact Section Style
 *************************************/

.contact-area {
}
.contact-area .section-title {
  margin-bottom: 35px;
}

.contact-form-box {
  margin-top: 30px;
}

.form-input-box {
  position: relative;
}

.contact-area .form input,
.contact-area .form textarea {
  width: 100%;
  padding: 10px 20px;
  border: none;
  color: #333;
  background-color: #f3f3f3;
  border-radius: 3px;
  height: 50px;
  font-weight: 500;
  border-bottom: 1px solid #000;
}
.contact-area .form textarea {
  height: auto;
  max-height: 300px;
  max-width: 100%;
}

#contact-form .contact-btn-box {
  text-align: center;
}

#contact-form .contact-btn-box .button {
  margin: 0;
}
.form-group {
  margin-bottom: 25px;
}

.contact-form .form-group {
  margin-bottom: 30px;
}

button,
input,
textarea:focus {
  border: none;
  outline: none;
}

.contact-store-location-box {
  display: flex;
  justify-content: center;
}

.contact-store-location-box i {
  padding-top: 15px;
}

.contactform {
  margin-top: 70px;
}

.formfield {
  display: flex;
  flex-direction: column;
  float: left;
  width: 49%;
}

.formfield input {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 30px -3px;
  height: 45px;
  margin-bottom: 20px;
  padding: 10px;
}

.formmessage {
  float: right;
  width: 49%;
}

::placeholder {
  color: black;
  opacity: 0.4; /* Firefox */
}

.btn_sec {
  display: inline-block;
  width: 100%;
}

.btn_sec button {
  margin: 15px auto 0;
  display: block;
  width: auto;
  padding: 10px 25px;
  background: linear-gradient(90deg, #000, #9c27b0 50%, #000);
  /* color: #fff; */
  font-size: 20px;
}

.formmessage textarea {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 30px -3px;
  padding: 10px;
  border: none;
  width: 100%;
}

.queryform {
  /* display: flex;
    flex-flow: column wrap;
	align-content: space-between; */
  height: 200px;
  margin-top: 50px;
}

.queryform input,
.queryform textarea {
  width: 49.4%;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 30px -3px;
  border: solid 1px #eaeaea;
}

.queryform input {
  height: 40px;
  margin-bottom: 15px;
}

.queryform textarea {
  height: 150px;
}

li.slide a {
  display: block;
  cursor: pointer;
}

.single-store-location {
  /* padding: 30px 15px;
    background: linear-gradient(90deg,#000,#9c27b0 50%,#000);
    border: 1px solid #000;
	margin-top: 30px; */
  padding-left: 25px;
}
.single-store-location i {
  /* color: #fff; */
  margin-right: 10px;
}

.single-store-location h3 {
  /* color: #fff; */
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}
.single-store-location p {
  /* color: #fff; */
}
.single-store-location p a {
  color: #333;
  letter-spacing: 0px;
}
.single-store-location p {
  color: #333;
  letter-spacing: 0px;
  margin-top: 0px;
  font-weight: 400;
}

.social-media-icon {
  margin-top: 10px;
}
.social-media-icon li {
  display: inline-block;
  margin-right: 10px;
}
.social-media-icon li a {
  color: #333;
  background: transparent;
  border-radius: 10px;
  width: 40px;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
}
.social-media-icon li a:hover {
  opacity: 0.4;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #333;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #333;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #333;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #333;
}

.contact-area .form-message.success {
  background: #03b103;
  /* color: #fff; */
  padding: 10px 15px;
  border-radius: 3px;
  display: block;
  margin-bottom: 15px;
}

.contact-area .form-message.error {
  background: #ff4d15;
  /* color: #fff; */
  padding: 10px 15px;
  border-radius: 3px;
  display: block;
  margin-bottom: 15px;
}

.messages {
  margin-top: 30px;
}

.alert-success {
  margin-left: 15px;
}

.hidden {
  display: none;
}

.darkbg {
  background: #23232a;
}

/**************************************
 * 12.Partner Logo Section Style
 *************************************/

.partner-area {
  padding: 70px 0px;
  overflow: hidden;
}
.partner-item {
  padding: 5px 0px;
  margin: 5px 10px;
}
.partner-area .partner-carousel img {
  opacity: 0.8;
  max-width: 100%;
  width: inherit !important;
  margin: 0 auto;
}

.partner-area .partner-carousel img:hover {
  opacity: 1;
  cursor: pointer;
}

/**************************************
 * download app Section Style
 *************************************/
.download-buttons-wrap {
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}
.download-buttons {
  margin: 0 10px;
}

/**************************************
 * Footer Section Style
 *************************************/
.footer-copyright-text h3 {
  /* color: #fff; */
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  line-height: 28px;
}
.book-area {
  padding: 30px 0;
  background: linear-gradient(90deg, #000, #9c27b0 50%, #000);
}

.book-area .col-md-8 {
  display: flex;
  align-items: center;
}

.book p {
  /* color: #fff; */
}
.site-footer .single-store-location {
  padding: 0px;
  background: transparent;
  border: 0px solid #000;
  margin-top: 10px;
}
.site-footer .single-store-location i {
  /* color: #fff; */
  margin-right: 10px;
}

.site-footer .single-store-location h3 {
  /* color: #fff; */
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}
.site-footer .single-store-location p {
  /* color: #fff; */
}
.site-footer .single-store-location p a {
  /* color: #fff; */
  letter-spacing: 0px;
}
.site-footer .single-store-location p {
  letter-spacing: 0px;
  margin-top: 0px;
  font-weight: 400;
}

.site-footer p {
  color: #b0afaf;
}

.site-footer {
  background-color: #070707;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
}
.site-footer hr {
  border-top-color: #424242;
  opacity: 0.5;
}
.site-footer hr.small {
  margin: 20px 0;
}
.site-footer h6 {
  /* color: #fff; */
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}
.site-footer a:hover {
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}
.footer-links li {
  display: block;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  text-decoration: none;
}
.footer-links.inline li {
  display: inline-block;
}
.site-footer .social-icons {
  text-align: right;
}
.site-footer .social-icons a {
  width: 33px;
  height: 33px;
  line-height: 35px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d;
}
.copyright-text {
  margin: 0;
}
@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}
.text-white p {
  /* color: #fff; */
}
.f-30 p {
  font-size: 37px;
}
.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}
.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px;
}
.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  /* color: #fff; */
  background-color: #29aafe;
}
.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}
.social-icons a.facebook:hover {
  background-color: #3b5998;
}
.social-icons a.twitter:hover {
  background-color: #00aced;
}
.social-icons a.linkedin:hover {
  background-color: #007bb6;
}
.social-icons a.dribbble:hover {
  background-color: #ea4c89;
}
@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }

  .modalwd {
    max-width: 100%;
    margin: 0 30px;
  }

  a.vrai-chat-btn {
    margin: 0 auto;
  }

  .book-area .col-md-8 {
    justify-content: center;
  }
}

/* carousel css for testmonials */

.carousel-root {
  width: 65% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.eve_pg .carousel-root {
  width: 100% !important;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.carousel .slide img {
  /* width: 139px !important;
	  */
  width: auto !important;
  border-radius: 50%;
  height: 130px;
}
.testimonial-slide .carousel .slide img {
  width: auto !important;
  border-radius: 0% !important;
  height: 200px;
}

.carousel * {
  font-size: 20px;
}

.myCarousel {
  margin-top: -6%;
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height: auto;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 4% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.carousel .slider {
  width: 33.33%;
}
.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.carousel.carousel-slider .control-arrow {
  width: 15px;
  height: 30px;
  display: flex;
  justify-content: center;
}

.carousel-root .carousel .control-arrow,
.carousel-root .carousel.carousel-slider .control-arrow {
  opacity: 1;
}
.carousel-root .carousel .control-arrow:before,
.carousel-root .carousel.carousel-slider .control-arrow:before {
  content: none;
}

.carousel-root .carousel.carousel-slider {
  overflow: inherit;
}

.carousel-root .carousel .control-next.control-arrow {
  right: -50px;
}

.carousel-root .carousel .control-prev.control-arrow {
  left: -50px;
}

.control-arrow {
  content: "";
  background: currentColor;
  width: 15px;
  clip-path: polygon(
    0 10px,
    calc(100% - 15px) 10px,
    calc(100% - 15px) 0,
    100% 50%,
    calc(100% - 15px) 100%,
    calc(100% - 15px) calc(100% - 10px),
    0 calc(100% - 10px)
  );
  animation: a2 1s infinite linear;
}

.control-prev {
  transform: scaleX(-1);
}
@keyframes a2 {
  90%,
  100% {
    flex-grow: 0.5;
  }
}

.myCarousel p {
  line-height: 29px !important;
  color: #333;
  font-size: 18px;
  font-family: sans-serif;

  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.btn.btn-secondary {
  /* cursor: pointer;
  position: absolute;
  top: 70px;
  right: 3px;
  background-color: #280a2d; */
}

.modal-header .btn-close {
  display: none;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: linear-gradient(180deg, #000, #9c27b0 50%, #000) !important;
  height: 40px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

/* past events carousel css custom */
.testimonial-slide .carousel .date img {
  width: auto !important;
  border-radius: 0% !important;
  height: auto !important;
}
.testimonial-slide .carousel .control-dots .dot {
  height: 7px !important;
  width: 15px !important;
  border-radius: 5% !important;
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}
.testimonial-slide .carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
}
/* end past carousel css */

/* sitemap css */
.sitemap-web a {
  color: #007bff !important;
}
.sitemap-web a:hover {
  color: orange !important;
}

/* add css new install page */
.install body {
  /* background-color: white; */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  color: #3b3b3b;
}
.padbot {
  margin-bottom: 30px;
}
.install h2,
h3 {
  letter-spacing: 1px;
}
img.banner-logo.logo {
  width: 100%;
}

.hdrbg_part {


  padding: 100px 20% 80px;
  text-align: center;
  /* color: white; */
  margin-bottom: 70px;
  min-height: 220px;
}

.hdrbg_part img {
  width: 181px;
}

.hdrbg_part h2 {
  font-size: 34px;
  margin: 0;
  margin-top: 50px;
  /* color: white; */
}

.hdrbg_part p {
  padding: 60px 0 40px;
  font-size: 17px;
  line-height: 26px;
}

.hdrbg_part a {
  background: #148ef9;
  border-radius: 50px;
  line-height: 60px;
  height: 60px;
  font-size: 25px;
  /* color: white; */
  display: inline-block;
  padding: 0 30px;
  text-decoration: none;
  letter-spacing: 0.5px;
  font-weight: 500;
}

section.gfda_steps h4:first-of-type {
  margin-bottom: 20px;
  font-weight: 400;
}

section.gfda_steps h4 {
  font-size: 18px;
}

section.gfda_steps h4 strong {
  font-weight: 700;
}

img {
  width: 100%;
}

section.gfda_steps img,
section.gfda_steps h4,
section.gfda_steps p {
  margin-bottom: 50px;
  margin-top: 0;
}

section.gfda_steps p {
  font-size: 16px;
  color: #818181;
}

section.gfda_steps h3 {
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 28px;
}

.vlogo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.install .heading {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

hr {
  margin: 50px 0px;
}

.price-item .bestseller {
  /* background: url(../img/best-seller.png) no-repeat center center / cover;
	position: absolute;
    width: 150px;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: -11px;*/
  margin-top: -120px;
}

/* New Site Css */




.fin_agr{
  box-shadow: 0 3px 10px #ccc; 
  /* min-height: 500px; */
     
}

.titlebar{
  padding: 25px 15px;
}

.fin_agr h1{
  font-size: 26px;
  font-weight: 700;
  margin: 0;  
}

.hd_txt div{
  font-weight: 700;
  padding-bottom: 10px;
}




button{
  background: #00b1bb !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: 3px !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  padding: 10px 30px !important; 
  letter-spacing: 2px !important;
  color: #000 !important;
}


.hovmenu button{
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: 3px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  padding: 0.5rem 1rem !important; 
  letter-spacing: 2px !important;
  color: inherit !important;
}

img.logo{
  width: 40%;
  margin-top: 10px;
}

.nav-link{
  color: inherit;
  cursor: pointer !important;
}

a{
  /* color: #000; */
  text-decoration: none;
  font-size: 20px;
 /*  margin: 0 15px; */
  font-weight: 600;
  color: inherit;
}

a.active, .nav a.active{
  font-weight: 700;
  /* border-bottom: solid 3px #00b1bb;
  padding-bottom: 6px; */
  color: #00b1bb !important;
    padding: 0.5rem 1rem;
}

.menu{
  padding: 0.5rem 1rem;
  cursor: pointer !important;
}

.heading-token {
  float: left;
  padding: 18px;
  text-align: left;
}

.btn-outline-success{
  /* position: absolute;
  top: -430px;
  right: 30px;
  color: inherit !important; */
  max-width: fit-content;
  margin: -60px 10px 10px auto; 
}

.addtable{
  display: flex;
    flex-direction: column;
    margin-top : 40px
}

.tablepos{
  order: 1;
}

.nav-link.nav-item{
  padding: 0;
}

a.nav-link.classpadding.nav-link {
  /* padding: 10px; */
}

.nav-link, .nav a, .navbar-nav a {
  /* color: #000; */
  letter-spacing: 2px;
  font-weight: 600;
  color: inherit;
}

.nav-link:focus, .nav-link:hover {
  color: inherit;
}

.nav-link.horz_menu.t_ken.nav-item.show.dropdown .dropdown-menu.show a{
  cursor: pointer;
}

.nav-link.horz_menu.t_ken.nav-item.show.dropdown .dropdown-menu.show a:first-child{
  padding-left: 0;
}

.nav-link.horz_menu.t_ken.nav-item.show.dropdown .dropdown-menu.show a:hover{
  background: none;
}

.cryptoicon{
  width: 50px;
}

.crypto_list  {
  flex-wrap: wrap;
}

.crypto_list li{
  margin: 0px 0 40px;
    flex: 0 0 16.66%;
}

li span{
  display: block;
}

.userpanel{
  background: #2a2a2a;
    padding: 22px 26px;
    border-radius: 40px;
    display: inline-block;
}

.userpanel img{
  width: 28px;
}

  a:hover{    
  /* font-weight: 700;
  border-bottom: solid 3px #95ded4;
  padding-bottom: 6px; */
}  

.crn_bal{
  font-weight: 500;
  padding-right: 40px;
}

body{
  font-family: 'Oswald', sans-serif;
  letter-spacing: 2px;
}

.crn_bal span{
  font-size: 40px;
 /*  color: #000; */
  border-bottom: solid 5px #00b1bb;
  margin-left: 15px;
  font-weight: 600;
  letter-spacing: 2px;
}

.col-lg-3.text-end {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trade{  
  padding-left: /* 200px */ 0;
  color: #0d6efd;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: end;
  align-items: center;
  float: right;
}

.selectfield{
  border: none;
  margin: 0 20px;
}

.crn_bal a{
  color: #c6c6c7;
}

.nopage{
  padding: 0 20px;
}

.offbtn img{
  width: 60%;
}

.table>:not(:first-child) {
  border-top: 0px solid currentColor;
}


/* table tr:nth-of-type(even) {
  background-color: rgba(0,0,0,.05);
} */

.table{
  margin-bottom: 0;
  color: inherit;
  --bs-table-hover-color: inherit;
  --bs-table-hover-bg: #666;
  position: relative;
}

.table-striped>tbody>tr:nth-of-type(odd)>*{
  color: inherit;
}

.table td, .table th {
  border-top: 0px solid #dee2e6;
}

.table-hover tbody tr:hover {
  color: inherit;
  background-color: inherit;
}

.table-striped tbody tr:nth-of-type(odd){
  color: inherit;
}



.token, .weight, .assay, .piddate, .secftr, .len, .wdt, .hgt, .location, .price, .owner, .sechol, .encum{
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.token{
  width: 120px;
}

.view{
  width: 98px;
  padding-left: 7px !important;
    padding-right: 5px !important;
    text-align: center;
}

.weight{
  width: 125px;
}

.assay{
  width: 90px;
}

.piddate{
  width: 210px;
}

.secftr{
  width: 220px;
}

.len{
  width: 100px;
}

.wdt{
  width: 90px;
}

.hgt{
  width: 95px;
}

.location{
  width: 170px;
}

.price{
  width: 125px;
}

.owner{
  width: 100px;
}

.sechol{
  width: 195px;
}

.encum{
  width: 130px;
}

.delbtn {
  padding: 0 !important;
  width: 10px;
}

.delbtn button{
  padding: 2px 8px !important;
  border-radius: 39px !important;
  font-size: 14px !important;
  margin-top: 18px;
}




.bg-light {
  background-color: transparent !important; 
}



.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
    background-color: white; 
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: /* #2196F3 */ #00b1bb;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.swpos{
  position: absolute;
    right: 190px;
    top: 41px;
    z-index: 9999;
    /* width: 80px; */
}


.fin_agr .horz_menu > .dropdown-menu.show{
  /* position: relative !important; */
}

.nav-link.horz_menu.nav-item.show.dropdown {
  height: 80px;
}


.fin_agr .horz_menu > .dropdown-menu.show div:first-child{
  margin-left: 0px;
}

.horz_menu~a.active{
  margin-left: 0rem;
}

.horz_menu~a{
  margin-left: 1rem;
}

.hovmenu{
  display: flex;
  
  width: 100%;
}

.navbar-expand-lg .navbar-collapse{
  /* display: none !important; */
}


/* ============ desktop view ============ */
@media all and (min-width: 992px) {
.navbar .nav-item .dropdown-menu{ display: none; }
.navbar .nav-item:hover .nav-link{   }
.navbar .nav-item:hover .dropdown-menu{ display: block; }
.navbar .nav-item .dropdown-menu{ margin-top:0; }
}	
/* ============ desktop view .end// ============ */

.navbar{
  padding: 0;
}

.navbar-brand{
  margin: 0px;
  padding: 0;
  text-align: center;
}

.navbar-expand-lg .navbar-nav .nav-link {
  /* padding: 0 0 10px; */
  /* color: #000; */
  color: inherit;
}

.dropdown-menu{
  box-shadow: 0 2px 7px #bababa;
  border: none;
  padding: 0;
  margin: 0;
}

a.dropdown-item{
  margin: 0;
  /* color: #000; */
  font-weight: 700;
  padding: 0.45rem 1.5rem;
  cursor: pointer;
}

.dropdown-menu.show { 
  width: auto;   
}

.fin_agr .nav-link.nav-item, .fin_agr a.classpadding  {
  padding: 0;
  margin: 0.5rem 1rem;
}



.fin_agr .horz_menu .dropdown-menu.show{
  display: flex;
  box-shadow: none;
  
}

.horz_menu .dropdown-menu.show .dropdown-menu.show{
  display: block;
  box-shadow: inset 0 -2px 7px #bababa;
}

.fin_agr .dropdown-menu.show{
  inset: 8px auto auto 0px !important;
  border-radius: 0;
  box-shadow: inset 0 -2px 7px #bababa;
}

.fin_agr .nav-link { 
  padding: 0;
}


.dropdown-item:focus, .dropdown-item:hover{
  background-color: #c9edf0;
}

a:hover.dropdown-item{
  /* color: #000; */
}

.dropdown-toggle::after{
  content: none;
}

.gry_txt{
  font-size: 20px;
  /* color: #999ba2; */
}

ul.list_txt{
  padding: 0;
  list-style: none;
}

ul.list_txt li div, .hd_txt div{
  width: 100%;
  text-align: center;
}

a.vw-lnk{
  background: #00b1bb;
  border-radius: 5px;
  box-shadow: 0 1px 4px #a9a9a9;
  font-size: 14px;
  /* color: #000 */;
  padding: 5px 10px;
  text-transform: uppercase;
  display: block;
  color: inherit;
}

.list_txt li{
  padding: 7px 0;
}


.list_txt li:nth-child(2n+1){
 background: #fafafd;
}

/* .lockicon{
  background: url('../img/lock.png') no-repeat 15px 2px / 20px auto;
} */



.faform{
  /* width: 800px; */
  margin: 0 auto;
  border: solid 1px #ccc;
  padding: 25px;
  z-index: 99999;
  position: absolute;
  background: #fff;
  top: 0;
  right: 0;
}

.faform label{
  display: block;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 5px;
}

.faform input, .faform select{
  width: 100%;
  background: #fafafd;
  border: solid 1px #e6e7e9;
  padding: 10px;
  margin-bottom: 25px;
}

/* .overlaybg{
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(10, 8, 8, 0.342);
  right: 0;
  bottom: 0;
} */



.faform input.formsubmit {
  background: #93dfd6;
  width: auto;
  border: none;
  border-radius: 3px;
  padding: 10px 30px;
  margin: 25px 0 0 auto;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  box-shadow: 0 2px 5px #40978c;
}

.faform h3{
  border-bottom: solid 1px #e6e7e9;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.btn-close{
  box-shadow: none;
  background: none;
}

.modal-header .btn-close {
  padding: 0;
  margin: 0 50px 0 0;
  color: #0d6efd;
}

.btn-primary {   
  background-color: #0d6efd;
  border-color: #0d6efd;
  margin: 30px auto;
  display: block;
}

.modal-dialog {
  margin: 1.75rem 0 0 auto;
}

.btn-close:focus { 
  box-shadow: none;   
}

.table-bordered{
  text-align: center;
 
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.table-bordered th{
  /* color: #000; */
  text-align: center;
}

.mobview{
  display: none;
}


/* New Site Css */



























@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    height: 269px;
  }

  .carousel .slide img {
    width: auto !important;
    border-radius: 50%;
    height: 130px;
  }
}

@media (min-width: 1367px) and (max-width: 1640px) {
  .hovmenu .menu, .hovmenu a.nav-link{
    font-size: 1.1vw;
    }
    .menu {
      padding: 0.5rem 0.5rem;
  }

  .btn-outline-success {
    top: 200px;
  }

  .hovmenu button{
    font-size: 1.1vw !important;
  }

  .t_menu a.menu{
    font-size: 20px; 
  }
}

@media (min-width: 1200px) and (max-width: 1366px) {
  .carousel-root {
    width: 84% !important;
  }

  .swpos {    
    right: 130px;
    top: 28px;  
}

.t_menu a.menu{
  font-size: 20px; 
}

 .hovmenu .menu, .hovmenu a.nav-link, .hovmenu a.active{
  font-size: 1.3vw;
  }

.navbar-nav .menu, .navbar-nav a.nav-link, .navbar-nav a.active{
font-size: 1.2vw;
padding: 0.5rem 0.5rem !important;
}

.navbar-expand-lg .navbar-nav .nav-link.nav-item.dropdown{
padding-right: 0rem;
padding-left: 0rem;
}


.crn_bal span {
  font-size: 30px;
}

.btn-outline-success {
  top: 183px;
}

.nav-link a, .menu, a.nav-link  { 
 
  padding: 0.5rem 0.4rem !important; 
}

.hovmenu button{
  font-size: 1.3vw !important;
  padding: 0.5rem 0.4rem !important;
}


}



@media (min-width: 1000px) and (max-width: 1199px) {

  .t_menu a.menu{
    font-size: 20px; 
  }

.hovmenu .nav-link a, .hovmenu .menu, .hovmenu a.nav-link  { 
  font-size: 1.1vw;   
  padding: 0.5rem 0.4rem !important; 
}

.hovmenu button{
  font-size: 1.1vw !important;
  padding: 0.5rem 0.4rem !important;
}

.menu {
  padding: 0.5rem 0.2rem;
}
a.active, .nav a.active {  
  padding: 0.5rem 0.2rem;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0.2rem;
  padding-left: 0.2rem;
}

.trade {
  padding-left: 0px; 
  justify-content: end; 
}

.swpos { 
  right: 130px;
  top: 25px; 
}

.btn-outline-success {
  top: 151px;
}

.crn_bal span {
  font-size: 24px;
}

.crn_bal { 
  padding-right: 20px;
}

.userpanel {
  padding: 14px 18px;
}


}



@media (min-width: 768px) and (max-width: 1199px) {
  .carousel-root .carousel .slider {
    width: 50% !important;
  }
  .button {
    padding: 10px 30px;
    font-size: 20px;
  }
  .single-price-item.active .pricing-header .pricing-title {
    font-size: 20px;
  }
  .single-price-item.active .pricing-header .pricing-description {
    top: 52%;
  }
  .single-price-item .pricing-header .pricing-description {
    top: 52%;
  }

  .single-price-item .pricing-header .pricing-title {
    font-size: 20px;
  }

  .carousel-root {
    width: 84% !important;
    margin: auto !important;
    margin-top: 3% !important;
  }


  .hovmenu .nav-link a, .hovmenu .menu, .hovmenu a.nav-link  {    
    padding: 0.5rem 0.4rem !important; 
  }


}

@media (min-width: 600px) and (max-width: 999px) {
  .col-lg-3.col-md-6.col-sm-12.price-item,
  .col-lg-3.col-md-6.col-sm-12.price-item.activeplan {
    flex: 0 0 50%;
    max-width: 50%;
    margin: 0px auto 50px;
  }

  .book-area .col-md-4 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .modal-content {
    padding-left: 20px;
    width: 95%;
  }








}


@media (min-width: 991px) and (max-width: 999px) {

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-nav a{
    padding-right: 5px;
    padding-left: 5px;
  }

  .navbar-nav a.menu, .navbar-nav a.nav-link, .navbar-expand-lg .navbar-nav .dropdown-toggle.nav-link{
    padding-right: 5px;
    padding-left: 5px;
    font-size: 1.2vw;
  }

  .userpanel {
    padding: 14px 18px;
}

.navbar-nav {
  margin-top: 0;
}

.nav-link a, .menu, a.nav-link  { 
  font-size: 1vw;   
  padding: 0.5rem 0.4rem !important; 
}

.hovmenu button{
  font-size: 1vw !important;
  padding: 0.5rem 0.4rem !important;
}

  

}



@media only screen and (max-width: 991px) {

  .deskview{
    display: none !important;
  }

  .mobview{
    display: block;
  }



}

@media only screen and (max-width: 999px) {

 
  
  .swpos{ 
    right: 90px;
    top: 13px;   
  }
  
 

    img.white-logo,
    img.black-logo {
      height: 50px;
      width: auto;
    }

    .menu, a.active, .nav a.active {
      padding: 0.5rem 0rem;
    }

    .navbar-nav {
      margin-top: 15px;
  }

  .crn_bal{    
    padding: 10px;
    background: #00b1bb;
    margin-top: 20px;   
    justify-content: center !important;
    margin-bottom: 30px;
}

.crn_bal span {
  font-size: 24px;
  border-bottom: solid 2px #00b1bb;
  
}

.table-striped, .table{
  overflow-x: auto;
  display: block;
}

button.btn-outline-success {
  border-radius: 0px !important;
  font-size: 16px !important; 
}

.btn-outline-success { 
  top: 185px;
  right: 17px;   
}

.fin_agr .nav {
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 71px;
}

.horz_menu~a.active {
  margin-left: 1rem;
}

}



@media only screen and (max-width: 768px) {
  .footer-copyright-text h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  /*.price-area .modal-body {
		 width: 50%;
	}*/

  .back-to-top.active {
    display: none;
  }

  .modal-content {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

  .pages-banner {
    padding: 0;
    height: 100px;
  }

  .pages-banner:after {
    content: "";
    position: absolute;
    background: linear-gradient(90deg, #000, #9c27b0 50%, #000);
    height: 100px;
  }

  .section-padding {
    padding: 30px 0;
  }

  .price-item .bestseller {
    margin-top: -80px;
  }

  .activeplan .plan-img.bestseller {
    margin-top: -100px;
  }

  .menuitem {
    padding-left: 20px;
    flex-wrap: wrap;
  }

  .bswrap {
    margin-top: 80px;
  }

  .activeplan .bs_txt {
    font-size: 32px;
  }

  .hovmenu {
    flex-direction: column;
    padding: 0 15px;
}

.hovmenu button{
  padding: 0.5rem 0rem !important;
}

.horz_menu~a {
  margin: 0.5rem 1rem;
}

.nav-link {
  padding: 0.5rem 0rem;
}

.fin_agr .horz_menu .dropdown-menu.show{
  display: block;
  box-shadow: inset 0 -2px 7px #bababa;
}

.fin_agr .horz_menu > .dropdown-menu.show div:first-child {
  margin-left: 1rem;
}

.nav-link.horz_menu.t_ken.nav-item.show.dropdown .dropdown-menu.show a:first-child {
  padding-left: 1.5rem;
}




}

@media only screen and (max-width: 599px) {
  .carousel-root .carousel .slider {
    width: 100%;
  }

  .price-area .col-lg-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-lg-3.col-md-6.col-sm-12.price-item {
    margin-bottom: 50px;
  }
  .col-lg-3.col-md-6.col-sm-12.price-item,
  .col-lg-3.col-md-6.col-sm-12.price-item.activeplan {
    flex: 0 0 80%;
    max-width: 80%;
    margin: 0px auto 50px;
  }
  .activeplan .plan-img {
    margin-top: -40px;
    margin-bottom: 0px;
  }

  .activeplan img {
    width: 290px;
  }

  .plan-img {
    display: inline-flex;
  }

  

}

@media only screen and (max-width: 480px) {












  .carousel-root .carousel .slider {
    width: 100%;
  }
  .col-lg-3.col-md-6.col-sm-12.price-item.activeplan .single-price-item {
    max-width: 100%;
  }

  .modal-body {
    /* width: 50% ;  */
  }

  .single-price-item .pricing-header .pricing-title {
    font-size: 20px;
  }


 



  .dropdown-menu.show {
    width: auto;
}

/*   .col-lg-3.col-md-6.col-sm-12.price-item.activeplan {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .carousel .slider {
    width: 100%;
  }

  .single-price-item:hover .pricing-header .pricing-title {
    font-size: 20px;
  }

  .activeplan .single-price-item .pricing-header .pricing-description {
    font-size: 13px;
    margin-top: -15px;
    padding-right: 90px;
    padding-left: 90px;
  }

  .activeplan .single-price-item .pricing-header {
    height: auto;
  } */


  .fin_agr h1 {
    font-size: 20px;
  
}

button.btn-outline-success{
  padding:10px 15px !important
}


}
